import { Controller } from "@hotwired/stimulus";
import { FetchRequest, get, post } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "patientId",
    "patientIdContainer",
    "loading",
    "patientCode",
    "patientFirstName",
    "patientLastName",
    "patientSearchButton",
    "upperArch",
    "lowerArch",
    "upperArchContainer",
    "lowerArchContainer",
    "protocol",
    "estimatedDays",
    "upperProtocol",
    "lowerProtocol",
    "upperCredits",
    "lowerCredits",
    "upperEstimatedDays",
    "lowerEstimatedDays",
    "totalCredits",
    "totalEstimatedDays",
  ];
  static values = {
    url: String,
    param: String,
  };

  connect() {
    this.toggleArches();
    this.protocolChange();
  }

  toggleArches() {
    if (this.hasUpperArchContainerTarget) {
      let upperArchContainer = this.upperArchContainerTarget;
      if (this.upperArchTarget.checked) {
        upperArchContainer.classList.remove("d-none");
      } else {
        upperArchContainer.classList.add("d-none");
      }
    }
    if (this.hasLowerArchContainerTarget) {
      let lowerArchContainer = this.lowerArchContainerTarget;
      if (this.lowerArchTarget.checked) {
        lowerArchContainer.classList.remove("d-none");
      } else {
        lowerArchContainer.classList.add("d-none");
      }
    }
  }

  protocolChange(event) {
    let totalCredits = 0;
    let upperCredits = 0;
    let lowerCredits = 0;
    let upperEstimatedDays = 0;
    let lowerEstimatedDays = 0;
    let totalEstimatedDays = 0;
    if (this.hasUpperArchContainerTarget) {
      if (this.upperArchTarget.checked) {
        if (this.hasUpperProtocolTarget) {
          this.upperProtocolTargets.forEach((e) => {
            if (e.checked) {
              console.log(e);
              upperCredits = parseInt(e.dataset.credits);
              totalCredits += upperCredits;
              if (this.hasUpperEstimatedDaysTarget) {
                upperEstimatedDays = parseInt(
                  this.upperEstimatedDaysTarget.value
                );
                totalEstimatedDays += upperEstimatedDays;
              }
            }
          });
        }
        if (this.hasUpperCreditsTarget) {
          this.upperCreditsTarget.value = upperCredits;
        }
      }
    }
    if (this.hasLowerArchContainerTarget) {
      if (this.lowerArchTarget.checked) {
        if (this.hasLowerProtocolTarget) {
          this.lowerProtocolTargets.forEach((e) => {
            if (e.checked) {
              console.log(e);
              lowerCredits = parseInt(e.dataset.credits);
              totalCredits += lowerCredits;
              if (this.hasLowerEstimatedDaysTarget) {
                lowerEstimatedDays = parseInt(
                  this.lowerEstimatedDaysTarget.value
                );
                totalEstimatedDays += lowerEstimatedDays;
              }
            }
          });
        }
        if (this.hasLowerCreditsTarget) {
          this.lowerCreditsTarget.value = lowerCredits;
        }
      }
    }
    if (this.hasTotalCreditsTarget) {
      this.totalCreditsTarget.innerHTML = totalCredits;
    }
    if (this.hasTotalEstimatedDaysTarget) {
      console.log("totalEstimatedDays", totalEstimatedDays);
      this.totalEstimatedDaysTarget.innerHTML = totalEstimatedDays;
    }
  }

  togglePatientSearch(event) {
    if (this.hasPatientIdContainerTarget) {
      let patientIdContainer = this.patientIdContainerTarget;
      let patientSearchButton = this.patientSearchButtonTarget;
      if (patientIdContainer.classList.contains("opacity-0")) {
        // patientIdContainer.classList.remove("d-none");
        patientIdContainer.classList.remove("opacity-0");
        patientSearchButton.classList.add("btn-primary");
        patientSearchButton.classList.remove("btn-outline-primary");
      } else {
        // patientIdContainer.classList.add("d-none");
        patientIdContainer.classList.add("opacity-0");
        patientSearchButton.classList.add("btn-outline-primary");
        patientSearchButton.classList.remove("btn-primary");
      }
    }
  }

  removePatientId(event) {
    this.patientIdTarget.value = "";
    setTimeout(() => {
      if (this.patientIdTarget && this.patientIdTarget.tomselect) {
        this.patientIdTarget.tomselect.clear();
        this.patientIdTarget.tomselect.clearOptions();
        this.patientIdTarget.tomselect.sync();
      }
    }, 400);
  }

  async patientChange(event) {
    let value = event.target.value;

    if (value && value != "") {
      if (this.hasLoadingTarget) {
        this.loadingTarget.classList.remove("d-none");
      }

      const response = await get(`/patients/${value}.json`, {
        responseKind: "json",
      });
      if (response.ok) {
        const body = await response.json;
        if (this.hasPatientCodeTarget) {
          this.patientCodeTarget.value = body.code;
        }
        if (this.hasPatientFirstNameTarget) {
          this.patientFirstNameTarget.value = body.first_name;
        }
        if (this.hasPatientLastNameTarget) {
          this.patientLastNameTarget.value = body.last_name;
        }
        console.log(body);
      }

      if (this.hasPatientIdContainerTarget) {
        let patientIdContainer = this.patientIdContainerTarget;
        let patientSearchButton = this.patientSearchButtonTarget;
        patientIdContainer.classList.add("opacity-0");
        patientSearchButton.classList.add("btn-outline-primary");
        patientSearchButton.classList.remove("btn-primary");
      }
      if (this.hasLoadingTarget) {
        this.loadingTarget.classList.add("d-none");
      }
    }
  }

  async patientChangeOld(event) {
    let params = new URLSearchParams();
    params.append(this.paramValue, event.target.selectedOptions[0].value);
    params.append("target", this.selectTarget.id);

    if (this.hasLoadingTarget) {
      this.loadingTarget.classList.remove("d-none");
    }

    await get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream",
    });

    setTimeout(() => {
      if (this.selectTarget && this.selectTarget.tomselect) {
        this.selectTarget.tomselect.clear();
        this.selectTarget.tomselect.clearOptions();
        this.selectTarget.tomselect.sync();
      }
      if (this.hasLoadingTarget) {
        this.loadingTarget.classList.add("d-none");
      }
    }, 400);
  }
}
