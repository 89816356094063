import { Offcanvas } from "bootstrap";
import { Controller } from "@hotwired/stimulus";
export default class extends Controller {
  connect() {
    let backdrop = document.querySelector(".offcanvas-backdrop");
    if (backdrop) {
      backdrop.remove();
    }
    document.querySelector("body").style = "";
    this.offcanvas = new Offcanvas(this.element);
    this.offcanvas.show();
    this.element.addEventListener("hidden.bs.offcanvas", (event) => {
      this.element.remove();
      let backdrop = document.querySelector(".offcanvas-backdrop");
      if (backdrop) {
        backdrop.remove();
      }
    });
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.offcanvas.hide();
    }
  }

  hideOffcanvas(e) {
    this.offcanvas.hide();
  }
}
