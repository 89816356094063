import NestedForm from "stimulus-rails-nested-form";

export default class extends NestedForm {
  connect() {
    super.connect();
  }

  add(e) {
    e.preventDefault();
    const content = this.templateTarget.innerHTML.replace(
      /NEW_2_RECORD/g,
      new Date().getTime().toString()
    );
    this.targetTarget.insertAdjacentHTML("beforebegin", content);
  }
}
