import { Controller } from "@hotwired/stimulus";
import { Tooltip } from "bootstrap";

export default class extends Controller {
  connect() {
    // window.setTimeout(() => {
    //   let alertList = this.element.querySelectorAll(".alert-autohide");
    //   Array.from(alertList).forEach((element) => {
    //     let button = element.querySelector("button");
    //     if (button) {
    //       button.click();
    //     }
    //   });
    // }, 3000);
    var tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
    document.addEventListener("turbo:click", () => {
      let loadingMask = document.getElementById("loading-mask");
      if (loadingMask) {
        loadingMask.classList.remove("d-none");
      }
    });
    document.addEventListener("turbo:visit", (e) => {
      let loadingMask = document.getElementById("loading-mask");
      if (loadingMask) {
        loadingMask.classList.add("d-none");
      }
    });
    document.addEventListener("turbo:frame-load", (e) => {
      let loadingMask = document.getElementById("loading-mask");
      if (loadingMask) {
        loadingMask.classList.add("d-none");
      }
    });
    document.addEventListener("turbo:submit-end", (e) => {
      let changeEvent = new Event("change");
      setTimeout(() => {
        let selects = document.getElementsByTagName("select");
        if (selects.length > 0) {
          Array.from(selects).forEach((item) => {
            item.dispatchEvent(changeEvent);
          });
        }
      }, 100);
    });
  }
}
