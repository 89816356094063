import { Controller } from "@hotwired/stimulus";
import { FetchRequest, get, post } from "@rails/request.js";

export default class extends Controller {
  static targets = [
    "email",
    "emailContainer",
    "defaultContainer",
    "completed",
    "approvalValue",
    "refusalMessageContainer",
  ];

  connect() {}

  showEmailField(event) {
    event.preventDefault();
    this.defaultContainerTarget.classList.add("d-none");
    this.emailContainerTarget.classList.remove("d-none");
  }

  hideEmailField(event) {
    event.preventDefault();
    this.emailTarget.value = "";
    this.emailContainerTarget.classList.add("d-none");
    this.defaultContainerTarget.classList.remove("d-none");
  }

  setComplete(event) {
    event.preventDefault();
    if (this.hasCompletedTarget) {
      this.completedTarget.value = true;
      document.forms[0].submit();
    }
  }

  approvalChange(event) {
    let approvalValue = "";
    if (this.hasApprovalValueTarget) {
      this.approvalValueTargets.forEach((e) => {
        if (e.checked) {
          approvalValue = e.value;
        }
      });
      let refusalMessageContainer = this.refusalMessageContainerTarget;
      if (approvalValue == "false") {
        refusalMessageContainer.classList.remove("d-none");
      } else {
        refusalMessageContainer.classList.add("d-none");
      }
    }
  }
}
