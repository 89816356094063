import { Controller } from "@hotwired/stimulus";
import { navigator } from "@hotwired/turbo";
const _ = require("lodash");

export default class extends Controller {
  connect() {
    let that = this;

    let selects = that.element.getElementsByTagName("select");
    if (selects.length > 0) {
      Array.from(selects).forEach((item) => {
        item.addEventListener("change", _.debounce(that.handleChange, 250));
      });
    }

    let inputs = that.element.getElementsByTagName("input");
    if (inputs.length > 0) {
      Array.from(inputs).forEach((item) => {
        item.addEventListener("keyup", _.debounce(that.handleChange, 250));
      });

      Array.from(inputs).forEach((item) => {
        if (item.type == "checkbox" || item.type == "radio") {
          item.addEventListener("change", _.debounce(that.handleChange, 250));
        }
      });
    }

    let datepickers = that.element.getElementsByClassName("datepicker");
    if (datepickers.length > 0) {
      Array.from(datepickers).forEach((item) => {
        item.addEventListener("change", _.debounce(that.handleChange, 250));
      });
    }
  }
  handleChange(event) {
    event.preventDefault();
    let loadingMask = document.getElementById("loading-mask");
    if (loadingMask) {
      loadingMask.classList.remove("d-none");
    }
    // event.target.name // => "user[answer]"
    // event.target.value // => <user input string>
    event.target.form.requestSubmit();
    // let element = document.getElementById("items_table");
    // if (element && element.src) {
    //   element.addEventListener(
    //     "turbo:frame-load",
    //     navigator.history.push(new URL(element.src))
    //   );
    // }
  }
}
