import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";

export default class extends Controller {
  static values = {
    url: String,
  };

  connect() {
    var url = this.urlValue + ".json";
    new TomSelect(this.element, {
      maxOptions: null,
      plugins: {
        change_listener: {},
        dropdown_input: {},
      },
      // load: function (query, callback) {
      //   var self = this;
      //   if (self.loading > 1) {
      //     callback();
      //     return;
      //   }
      //   fetch(url)
      //     .then((response) => response.json())
      //     .then((json) => {
      //       callback(json.result.list);
      //       self.settings.load = null;
      //     })
      //     .catch(() => {
      //       callback();
      //     });
      // },
      load: function (query, callback) {
        fetch(url + "?term=" + encodeURIComponent(query))
          .then((response) => response.json())
          .then((json) => {
            callback(json.items);
          })
          .catch(() => {
            callback();
          });
      },
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create">Aggiungi <strong>' +
            escape(data.input) +
            "</strong>&hellip;</div>"
          );
        },
        no_results: function (data, escape) {
          return (
            '<div class="no-results fs-6">Nessun risultato trovato per "' +
            escape(data.input) +
            '"</div>'
          );
        },
      },
    });
  }
}
