import { Controller } from "@hotwired/stimulus";
import flatpickr from "flatpickr";
import { it } from "flatpickr/dist/l10n/it.js";

// Connects to data-controller="ts-select"
export default class extends Controller {
  connect() {
    this.control = flatpickr(this.element, {
      altInput: true,
      altFormat: "d/m/Y",
      dateFormat: "Y-m-d",
      locale: "it",
    });
  }
  disconnect() {
    this.control.destroy();
  }
}
