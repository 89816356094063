import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["prostheticCount"];

  connect() {
    let that = this;

    let teeth = [
      "11",
      "12",
      "13",
      "14",
      "15",
      "16",
      "17",
      "18",
      "21",
      "22",
      "23",
      "24",
      "25",
      "26",
      "27",
      "28",
      "31",
      "32",
      "33",
      "34",
      "35",
      "36",
      "37",
      "38",
      "41",
      "42",
      "43",
      "44",
      "45",
      "46",
      "47",
      "48",
    ];

    let prosthetic_count = 0;
    teeth.forEach((tooth) => {
      that.setClass(tooth);
      let elements = that.element.getElementsByClassName(`tooth-${tooth}`);
      if (elements.length > 0) {
        Array.from(elements).forEach((item) => {
          item.addEventListener("mouseover", (event) => {
            let parents = that.element.getElementsByClassName(
              `tooth-${tooth}-parent`
            );
            if (parents.length > 0) {
              Array.from(parents).forEach((parent) => {
                parent.classList.add("tooth-hover");
              });
            }
          });
          item.addEventListener("mouseleave", (event) => {
            let parents = that.element.getElementsByClassName(
              `tooth-${tooth}-parent`
            );
            if (parents.length > 0) {
              Array.from(parents).forEach((parent) => {
                parent.classList.remove("tooth-hover");
              });
            }
          });
          item.addEventListener("click", (event) => {
            that.toggleTypology(tooth);
          });
        });
      }
    });
    this.setProstheticCount();
  }

  toggleTypology(tooth) {
    let element = document.getElementById(`tooth-${tooth}-typology`);
    if (element) {
      let current_value = element.value;
      let new_value = "";
      if (current_value == "") {
        new_value = "prosthetic";
      } else if (current_value == "prosthetic") {
        new_value = "implant";
      } else {
        new_value = "";
      }
      element.value = new_value;
      this.setClass(tooth);
    }
    this.setProstheticCount();
  }

  setClass(tooth) {
    let element = document.getElementById(`tooth-${tooth}-typology`);
    if (element) {
      let value = element.value;
      let parents = this.element.getElementsByClassName(
        `tooth-${tooth}-parent`
      );
      if (parents.length > 0) {
        Array.from(parents).forEach((parent) => {
          parent.classList.remove("prosthetic");
          parent.classList.remove("implant");
          if (value != "") {
            parent.classList.add(value);
          }
        });
      }
    }
  }

  setProstheticCount() {
    let elements = this.element.getElementsByClassName(`prosthetic`);
    let prosthetic_count = elements.length;
    if (this.hasProstheticCountTarget) {
      this.prostheticCountTarget.value = prosthetic_count;
    }

    let totalCount = 0;
    let upperCount = document.getElementById("upper_prosthetic_count");
    if (upperCount) {
      totalCount += parseInt(upperCount.value);
    }
    let lowerCount = document.getElementById("lower_prosthetic_count");
    if (lowerCount) {
      totalCount += parseInt(lowerCount.value);
    }
    let countElement = document.getElementById("prosthetic_count");
    if (countElement) {
      countElement.value = totalCount;
    }
  }
}
