import { Controller } from "@hotwired/stimulus";
import Choices from "choices.js";

// Connects to data-controller="ts-select"
export default class extends Controller {
  connect() {
    new Choices(this.element, {
      // removeItemButton: true,
    });
  }
}
