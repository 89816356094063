import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import Choices from "choices.js";

// Connects to data-controller="ts-select"
export default class extends Controller {
  connect() {
    new TomSelect(this.element, {
      maxOptions: null,
      plugins: {
        change_listener: {},
        dropdown_input: {},
        remove_button: {
          title: "Elimina",
        },
      },
      render: {
        option_create: function (data, escape) {
          return (
            '<div class="create">Aggiungi <strong>' +
            escape(data.input) +
            "</strong>&hellip;</div>"
          );
        },
        no_results: function (data, escape) {
          return (
            '<div class="no-results fs-6">Nessun risultato trovato per "' +
            escape(data.input) +
            '"</div>'
          );
        },
      },
    });
  }
}
